import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ErrorHandlerService } from '../../services/error.service';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  //idUser = ""
  constructor( private _router:Router ) { }

  ngOnInit() {
    //this.getInfoUser()
  }

  // getInfoUser(){
  //   this._load.show()
  //   this._user.getUser( this.idUser ).subscribe( response => {
  //     this._load.hide()
  //   }, err => {
  //     this._load.hide()
  //     //this._error.errorHandler()
  //   })
  // }

  // logOut(){
  //   console.log("SALIR")
  //   localStorage.removeItem("showAxio")
  //   localStorage.removeItem('user')
  //   localStorage.removeItem('token')
  //   this._router.navigate(["/ingresar"])
  // }

}
