import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { MenuService } from './services/menu.service';
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  isLayout = ""
  title = 'axio'

  constructor(
    private _router:Router,
    private _menu:MenuService
  ){}

  ngOnInit(){
    this.setFirebase()
    this.getLoginStatus()
    this._menu.show.subscribe( response => {

      if(!response["isShowMenu"]){
        this.isLayout = "login"
        this._router.navigate(["/ingresar"])
      } else {
        this.getLoginStatus()
      }
    })
  }

  setFirebase(){
    const app = initializeApp(environment.firebaseConfig);
    const analytics = getAnalytics(app);
    if( window.location.hostname == "gestion-escolar-valores.fundacionaxio.org"){
      logEvent(analytics, 'start_platform_education');
    } else if( window.location.hostname == "organizacion-valores.fundacionaxio.org"){
      logEvent(analytics, 'start_platform_org');
    } else if( window.location.hostname == "dashboard.fundacionaxio.org"){
      logEvent(analytics, 'start_platform_dashboard');
    }

  }

  getLoginStatus(){
    this._router.events.subscribe( event => {
      if(event instanceof NavigationStart ) {
        if ( event["url"].indexOf("/ingresar") == 0 ){
          this.isLayout = "login"
        } else if ( event["url"].indexOf("/recognition") == 0 ){
          this.isLayout = "recognition"
        } else {
          this.isLayout = "dashboard"
        }
      }
    })
  }


}
