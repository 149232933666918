import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  
  constructor( private _router: Router ) {}

  canActivate( route: ActivatedRouteSnapshot ) {

    const authorization = localStorage.getItem('token')
    const userRol = localStorage.getItem('rol')
    if(authorization){

      if( userRol == "1" ){
        this._router.navigate(["inicio"])
        return true
      }

      if( userRol == "3" ){
        this._router.navigate(["inicio"])
        return true
      }

      if( userRol == "4" ){
        this._router.navigate(["inicio"])
        return true
      }

      if( userRol == "5" ){
        this._router.navigate(["locations"])
        return true
      }


      localStorage.removeItem("showAxio")
      localStorage.removeItem('rol')
      localStorage.removeItem('token')
      localStorage.removeItem('school')
      localStorage.removeItem('state')
      this._router.navigate(["/ingresar"])
      return false

    }

    
    localStorage.removeItem("showAxio")
    localStorage.removeItem('rol')
    localStorage.removeItem('token')
    localStorage.removeItem('school')
    localStorage.removeItem('state')
    this._router.navigate(["/ingresar"])
    return true


  }
  
}
