import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { getUserResult } from '../interfaces/getUser.interfaces';
import { getProfileUserResult } from "../interfaces/getProfileUser.interfaces";
import { setLoginResult } from "../interfaces/setLogin.interfaces";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private url = environment.api;

  constructor(private _http: HttpClient) {}

  private setHeaders(): HttpHeaders {
    let token = environment.token//localStorage.getItem("token");
    let headers = new HttpHeaders().set("Authorization", `Bearer ${token}`);
    return headers;
  }

  public getAllUsers() {
    let headers = this.setHeaders();
    return this._http.get(this.url + "/user", { headers });
  }

  public getAllStatistics( id ) {
    let userRol = localStorage.getItem('rol')
    let headers = this.setHeaders();
    if( userRol == '4'){
      return this._http.get(this.url + "/user/statistics/org/" + id, { headers });
    }
    return this._http.get(this.url + "/user/statistics/school/" + id, { headers });
  }

  public getCurrentID(): Observable<getUserResult> {
    let headers = this.setHeaders();
    let token = localStorage.getItem("token");
    const data = { token: token }
    return this._http.post<getUserResult>(this.url + "/user/token", data, { headers });
  }

  public getProfileUser( id ): Observable<getProfileUserResult> {
    let headers = this.setHeaders();
    return this._http.get<getProfileUserResult>(this.url + "/user/profile/" + id, { headers });
  }

  public getChildernUser( id ){
    let headers = this.setHeaders();
    return this._http.get(this.url + "/user/children/" + id, { headers });
  }

  public setProfileUser( data ){
    let headers = this.setHeaders();
    return this._http.post(this.url + "/user/profile", data, { headers });
  }

  public getUser( id ){
    let headers = this.setHeaders();
    return this._http.get(this.url + "/user/" + id, { headers });
  }

  public deleteUser( id ){
    let headers = this.setHeaders();
    return this._http.delete(this.url + "/user/" + id, { headers });
  }

  public setUser( data ){
    let headers = this.setHeaders();
    return this._http.post(this.url + "/user/create", data, { headers });
  }

  public putUser( id, data ){
    let headers = this.setHeaders();
    return this._http.put(this.url + "/user/" + id, data, { headers });
  }

  public uploadImage( id, data ){
    let headers = this.setHeaders();
    return this._http.post(this.url + "/user/avatar/" + id, data, { headers });
  }

  public setLogin( data ): Observable<setLoginResult> {
    let headers = this.setHeaders();
    return this._http.post<setLoginResult>(this.url + "/user/auth", data, { headers });
  }

  public putEvaluation( data, id ){
    let headers = this.setHeaders();
    return this._http.put(this.url + "/user/evaluation/" + id, data, { headers });
  }

  
}
