import { Injectable, EventEmitter, Output } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  @Output() show: EventEmitter<any> = new EventEmitter()
  constructor() { }


}
