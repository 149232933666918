import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LoginGuard } from './shared/guards/login.guard';


const routes: Routes = [
  {
    path: 'ingresar',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: '',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'recognition',
    loadChildren: () => import('./modules/recognition/recognition.module').then(m => m.RecognitionModule)
  },
  {
    path: 'certification',
    loadChildren: () => import('./modules/certification/certification.module').then(m => m.CertificationModule)
  },
  {
    path: 'inicio',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'courses',
    loadChildren: () => import('./modules/courses/courses.module').then(m => m.CoursesModule)
  },
  {
    path: 'infographics',
    loadChildren: () => import('./modules/infographics/infographics.module').then(m => m.InfographicsModule)
  },
  {
    path: 'blog',
    loadChildren: () => import('./modules/blog/blog.module').then(m => m.BlogModule)
  },
  {
    path: 'collaborators',
    loadChildren: () => import('./modules/collaborators/collaborators.module').then(m => m.CollaboratorsModule)
  },
  {
    path: 'diagnosis',
    loadChildren: () => import('./modules/diagnosis/diagnosis.module').then(m => m.DiagnosisModule)
  },
  {
    path: 'evaluation',
    loadChildren: () => import('./modules/evaluation/evaluation.module').then(m => m.EvaluationModule)
  },
  {
    path: 'lessons',
    loadChildren: () => import('./modules/lessons/lessons.module').then(m => m.LessonsModule)
  },
  {
    path: 'locations',
    loadChildren: () => import('./modules/locations/locations.module').then(m => m.LocationsModule)
  },
  {
    path: 'messages',
    loadChildren: () => import('./modules/messages/messages.module').then(m => m.MessagesModule)
  },
  {
    path: 'news',
    loadChildren: () => import('./modules/news/news.module').then(m => m.NewsModule)
  },
  {
    path: 'training',
    loadChildren: () => import('./modules/training/training.module').then(m => m.TrainingModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'dictionary',
    loadChildren: () => import('./modules/dictionary/dictionary.module').then(m => m.DictionaryModule)
  },
  {
    path: 'values',
    loadChildren: () => import('./modules/values/values.module').then(m => m.ValuesModule)
  },
  {
    path: 'categories',
    loadChildren: () => import('./modules/categories/categories.module').then(m => m.CategoriesModule)
  },
  {
    path: 'school',
    loadChildren: () => import('./modules/school/school.module').then(m => m.SchoolModule)
  },
  {
    path: 'products',
    loadChildren: () => import('./modules/products/products.module').then(m => m.ProductsModule)
  },
  {
    path: 'coupons',
    loadChildren: () => import('./modules/coupons/coupons.module').then(m => m.CouponsModule)
  },
  {
    path: 'shopping',
    loadChildren: () => import('./modules/shopping/shopping.module').then(m => m.ShoppingModule)
  },
  {
    path: 'shopping',
    loadChildren: () => import('./modules/shopping/shopping.module').then(m => m.ShoppingModule)
  },
  {
    path: 'statistics',
    loadChildren: () => import('./modules/statistics/statistics.module').then(m => m.StatisticsModule)
  },
  { path: '**', redirectTo: '/ingresar', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
