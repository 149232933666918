import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'layout-cerificate',
  templateUrl: './cerificate.component.html',
  styleUrls: ['./cerificate.component.css']
})
export class CerificateComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    
  }

}
