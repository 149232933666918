import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { MenuService } from '../../services/menu.service';
import { getUserResult } from '../../interfaces/getUser.interfaces';
import { getProfileUserResult } from '../../interfaces/getProfileUser.interfaces';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  isDevelop = false
  userType = ""
  idSchool = ""
  idState = ""
  idData = ""
  constructor( 
    private _user:UserService,
    private _menu:MenuService
  ) { }

  ngOnInit() {
    this.userType = localStorage.getItem('rol')
    //this.isDevelop = !environment.production
    this.isDevelop = environment.production
    this.getUser()
  }

  getUser(){
    this._user.getCurrentID().subscribe( (respID:getUserResult) => {
      if( respID["statusCode"] == 200 ){
        this.idData = respID["data"]
        this.getProfileUser()
      }
    }, error => {
    })  
  }

  getProfileUser(){
    this._user.getProfileUser(this.idData).subscribe( (response:getProfileUserResult) => {
      if( response["statusCode"] == 200 ) {
        
        if(response["data"]["user"]){
          if(response["data"]["user"]["org"]){
            this.idSchool = response["data"]["user"]["org"]
          } else {
            this.idSchool = response["data"]["user"]["school"]
          }
          
        }
        
        if( response["data"]["state"] ){
          this.idState = response["data"]["state"]["_id"]
        }
        
      }
    })
  }

  logout(){
    this._menu.show.emit({ isShowMenu:false })
    localStorage.removeItem("showDeclarationAxio")
    localStorage.removeItem("showAxio")
    localStorage.removeItem('rol')
    localStorage.removeItem('token')
    localStorage.removeItem('school')
    localStorage.removeItem('state')
    localStorage.removeItem('org')
  }

}
