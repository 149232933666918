import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

export enum SubdomainType {
  org = "organizacion-valores",
  school = "gestion-escolar-valores",
  admin = "dashboard",
  invalid = "invalid"
}

@Injectable({
  providedIn: 'root'
})
export class DomainService {

  constructor(
    @Inject(DOCUMENT) private _document: Document
  ) { }

  isOrg(): boolean {
    const subdomain:string = this._document.location.host.split('.')[0];
    if( subdomain === SubdomainType.org ){
      return true
    } else  if( subdomain === SubdomainType.school ){
      return false
    } else  if( subdomain === SubdomainType.admin ){
      return false
    } else {
      window.location.href = "https://fundacionaxio.org";
      return false
    }
  }

  getSubDomain(): SubdomainType {
    const subdomain:string = this._document.location.host.split('.')[0];
    if( subdomain === SubdomainType.org ){
      return subdomain
    } else  if( subdomain === SubdomainType.school ){
      return subdomain
    } else  if( subdomain === SubdomainType.admin ){
      return subdomain
    } else {
      return SubdomainType.invalid
    }
  }

}
