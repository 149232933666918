export const environment = {
  production: false,
  api: 'https://api.fundacionaxio.org',
  token: '0AmqB5wint%#pDX9D$465Z05^eN',
  s3: 'https://s3.iwebsapp.com',
  firebaseConfig: {
    apiKey: "AIzaSyB6bUwbvI0j6pOrI9yx4B_SLtsxIwv-4iA",
    authDomain: "familia-con-valores.firebaseapp.com",
    databaseURL: "https://familia-con-valores.firebaseio.com",
    projectId: "familia-con-valores",
    storageBucket: "familia-con-valores.appspot.com",
    messagingSenderId: "1001293128369",
    appId: "1:1001293128369:web:162fd7c1dca2341759f890",
    measurementId: "G-T2GWJKLTSC"
  }
  // api: 'http://127.0.0.1:3004'
};